<template>
  <div class="trainingWrap container">
    <header>
      <headerTop></headerTop>
      <navTab></navTab>
    </header>
    <div class="main-content">
       <div class='content'>
        <div class='todayLiveCourseNum'>今天你有<span>{{liveCount}}</span>节直播课</div>
        <div class='LiveCourseList' v-for="(item,index) in liveCourseList" :key="index" @click='jumpLivePage(item)'>
          <div class='time'>
            <div class='data'>今天</div>
            <div class='hour'>{{item.beginTime.split(' ')[1]}}</div>
          </div>
          <div class='courseInfo'>
            <div class='name'>{{item.courseTitle}}</div>
            <div class='teachInfo'>
              <div class='isStart'>{{getLiveStr(item.liveStatus)}}</div>
              <div class='otherInfo'>
                <div class='teacher'>讲课人：{{item.chapterTeacher}}</div>
                <div>{{item.chapterTitle}}</div>
              </div>
            </div>
          </div>
        </div>
       </div>
       <div class='tabs'>
        <el-tabs v-model="activeName">
          <el-tab-pane label="正在学习的直播" name="noOver">
            <ul class='liveList' v-show='recordsData.length>0'>
              <li class='liveCourseCard' v-for="(item,index) in recordsData" :key='index'>
                <div class='dateItem days'>{{item.beginTime | liveDateSwitch}}</div>
                <div class='splitCol'>
                  <div class='point'></div>
                  <div class='straightLine'></div>
                </div>
                <div class='right-content'>
                  <div class='time'>
                    <div class='hour'>{{item.beginTime.split(' ')[1]}} ~ {{item.endTime.split(' ')[1]}}</div>
                    <div class='isStart'>{{item.isStart ? '直播中' : '尚未开始'}}</div>
                  </div>
                  <div class='card-content'  @click='jumpLivePage(item)'>
                    <div class='card-left'><div>{{item.courseTitle}}</div></div>
                    <div class='card-right'>
                      <div class='card-right-class'>{{item.courseTitle}}</div>
                      <div class='card-right-class1'>{{item.chapterTitle}}</div>
                      <div class='card-right-teacher'>讲课人：{{item.chapterTeacher}}</div>
                    </div>
                  </div>
                </div>
              </li>
             </ul>
             <emptyData v-show='recordsData.length==0'></emptyData>
          </el-tab-pane>
          <el-tab-pane label="已结束直播" name="over">
            <ul class='liveList' v-show='recordsData.length>0'>
              <li class='liveCourseCard' v-for="(item,index) in recordsData" :key='index'>
                <div class='dateItem days'>{{item.beginTime | liveDateSwitch}}</div>
                <div class='splitCol'>
                  <div class='point'></div>
                  <div class='straightLine'></div>
                </div>
                <div class='right-content'>
                  <div class='time'>
                    <div class='hour'>{{item.beginTime.split(' ')[1]}} ~ {{item.endTime.split(' ')[1]}}</div>
                    <div class='liveEnd'>已结束</div>
                  </div>
                  <div class='card-content'   @click='jumpLivePage(item)'>
                    <div class='card-left'><div>{{item.courseTitle}}</div></div>
                    <div class='card-right'>
                      <div class='card-right-class'>{{item.courseTitle}}</div>
                      <div class='card-right-class1'>{{item.chapterTitle}}</div>
                      <div class='card-right-teacher'>讲课人：{{item.chapterTeacher}}</div>
                    </div>
                  </div>
                </div>
              </li>
             </ul>
             <emptyData v-show='recordsData.length==0'></emptyData>
          </el-tab-pane>
        </el-tabs>
       </div>
    </div>
    <footer-com></footer-com>
  </div>
</template>

<script>
import emptyData from '@/components/emptyData.vue'
import footerCom from '@/components/footer.vue'
import { mapMutations } from 'vuex'
import navTab from '@/components/navTab.vue'
import headerTop from '@/components/header.vue'
export default {
  name: '',
  components: {
    footerCom,
    navTab,
    headerTop,
    emptyData
  },
  filters: {
    liveDateSwitch (value) {
      let splitArr = value.split(' ')[0].split('-')
      return `${splitArr[0]}年${splitArr[1]}月${splitArr[2]}日`
    }
  },
  props: {},
  watch: {
    activeName (newVal, oldVal) {
      console.log(oldVal, newVal)
      if (newVal == 'noOver') {
        this.isOverType = 'N'
        this.getRecordsData()
      } else {
        this.isOverType = 'Y'
        this.getRecordsData()
      }
    }
  },
  data () {
    return {
      userName: '',
      activeName: 'noOver',
      recordsData: [],
      liveCount: 0,
      liveCourseList: [],
      isOverType: 'N',
    }
  },
  methods: {
    ...mapMutations({
      setConfigBasicInfo: 'config/setBasicInfo',
      updateNickname: 'config/updateNickname',
      updateUserId: 'config/updateUserId',
    }),
    getLiveStr (status) {
      // NOT_START 未开始;LIVING 直播中;END 已结束
      let statusObj = {
        'NOT_START': '未开始',
        'LIVING': '直播中',
        'END': '已结束'
      }
      return statusObj[status]
    },
    // 直播
    jumpLivePage (playInfo) {
      console.log(playInfo)
      let {id, liveId, studentCourseId} = playInfo
      if (liveId) {
        // 设置直播间id
        this.channelId = liveId
        // 存储到本地
        localStorage.setItem('channelId', JSON.stringify(this.channelId))
        this.$router.push(`/live/${id}?id=${id}&studentCourseId=${studentCourseId}`)
      } else {
        this.$message({
          type: 'error',
          message: '此课程暂未开播！'
        })
      }
    },
    pageJumpLogin () {
      this.$router.push('/login')
    },
    // 获取local中用户详细信息
    getUserInfo () {
      let userinfo = localStorage.getItem('studentInfo')
        ? JSON.parse(localStorage.getItem('studentInfo'))
        : ''
      console.log(userinfo)
      if (userinfo) {
        let { stuName } = userinfo
        this.userName = stuName
        // 更新直播时的用户名
        this.updateNickname(this.userName)
      }
      let userIdInfo = localStorage.getItem('userInfo') ? JSON.parse(localStorage.getItem('userInfo')) : ''
      if (userIdInfo) {
        let {id} = userIdInfo
        this.updateUserId(id)
      }
    },
    // 获取今天的直播课
    getloadTodayLiveCourse () {
      this.$axios.get(this.$apiUrl.loadTodayLiveCourse, {params: {}}).then(res => {
        console.log(res)
        if (res.data.success) {
          let {liveCount, todayLiveChapterList} = res.data.data
          this.liveCount = liveCount
          this.liveCourseList = JSON.parse(JSON.stringify(todayLiveChapterList))
        }
      })
    },
    getRecordsData () {
      let reqOption = {
        'params': {'isOver': this.isOverType},
      }
      this.$axios.post(this.$apiUrl.queryStudentLiveChapterList, reqOption).then(res => {
        console.log(res)
        let liveList = res.data.data
        this.recordsData = JSON.parse(JSON.stringify(liveList))
        this.recordsData.forEach(item => {
          item['isStart'] = false
          let isStart = new Date() > new Date(item.beginTime)
          item['isStart'] = isStart
        })
      })
    },
  },
  mounted () {
    this.getUserInfo()
    this.getloadTodayLiveCourse()
    this.getRecordsData()
  },
}
</script>

<style scoped lang="scss">
.container {
  position: relative;
  min-height: 100vh;
  background-color: #fbfbff;
  header {
    background: url("../../assets/image/homeImg/headerBg.png");
    background-size: 100% 100%;
    height: 231px;
  }
}
  .main-content {
    width: 1240px;
    padding-bottom: 188px;
    margin: -84px auto 0;
    background-color: transparent;
    .content{
      margin-bottom: 50px;
      width: 1240px;
      background: #FFFFFF;
      box-shadow: 0px 6px 30px 1px rgba(0,0,0,0.12);
      border-radius: 7px 7px 7px 7px;
      opacity: 1;
      padding: 30px 80px 30px;
      .todayLiveCourseNum{
        font-size: 18px;
        font-family: Microsoft YaHei-Regular, Microsoft YaHei;
        font-weight: 400;
        color: #000000;
        margin-bottom: 21px;
        span{
          color: #FF6767;
          margin: 0px 10px
        }
      }
      .LiveCourseList{
        display: flex;
        font-size: 24px;
        font-family: Microsoft YaHei-Regular, Microsoft YaHei;
        font-weight: 400;
        color: #000000;
        padding: 15px 0px;
        border-bottom: 1px solid #707070;
        .time{
          width: 90px;
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;
          .hour{
            font-size: 16px;
          }
          .data{
            font-size: 18px;
            color: #000000;
            margin-bottom: 15px
          }
        }
        .courseInfo{
          padding-left: 30px;
          border-left: 1px solid #707070;
          flex: 1;
          .name{
            margin-bottom: 18px;
          }
          .teachInfo{
            display: flex;
            font-size: 18px;
            color: #666666;
            .isStart{
              width: 70px;
              text-align: center;
              background: #F2F2F2;
              border-radius: 4px 4px 4px 4px;
              font-size: 16px;
              font-family: Microsoft YaHei-Regular, Microsoft YaHei;
              font-weight: 400;
              color: #666666;
              height: 45px;
              line-height: 45px;
            }
            .otherInfo{
              flex: 1;
              padding: 0px 50px 0px 20px;
            }
          }
        }
      }
      .LiveCourseList:hover{
        cursor: pointer;
        .name{
          color: #4774df
        }
      }
      .LiveCourseList:last-of-type{
        border-bottom: 0px solid #707070;
      }
    }
    .tabs{
      min-height: 300px;
      .dateItem{
        font-size: 16px;
        font-family: Microsoft YaHei-Regular, Microsoft YaHei;
        font-weight: 400;
        color: #1C4ECC;
      }
      .yearAndMonth{
        margin-bottom: 5px;
      }
      .liveList{
        li{
          margin-bottom: 20px;
        }
        .liveCourseCard{
          display: flex;
          .days{
            margin: auto;
            width: 180px;
            padding: 0px 29px 0px 15px;
          }
          .splitCol{
            display: flex;
            flex-direction: column;
            .point{
              width: 6px;
              height: 6px;
              background: #000000;
              border-radius: 100%;
              opacity: 0.13;
              margin-bottom: 10px;
            }
            .straightLine{
              width: 4px;
              background: #000000;
              border-radius: 20px 20px 20px 20px;
              opacity: 0.13;
              flex: 1;
            }
          }
          .right-content{
            flex:1;
            margin-left: 38px;
            .time{
              display: flex;
              margin-bottom: 15px;
              .hour{
                font-size: 20px;
                font-family: Microsoft YaHei-Regular, Microsoft YaHei;
                font-weight: 400;
                color: #666666;
              }
              .isStart{
                margin-left:30px;
                padding: 6px 15px;
                background: #dbdbde;
                border-radius: 4px 4px 4px 4px;
                border: 1px solid #dbdbde;
                font-size: 14px;
                color: #666666;
              }
              .liveEnd{
                font-size: 14px;
                color: #1C4ECC;
                margin-left: 30px;
              }
            }
            .card-content{
              display: flex;
              background: #FFFFFF;
              box-shadow: 0px 6px 30px 1px rgba(0,0,0,0.12);
              border-radius: 6px 6px 6px 6px;
              padding: 20px 30px;
              cursor: pointer;
              .card-left{
                width: 147px;
                height: 98px;
                background: #FFFFFF;
                border-radius: 4px 4px 4px 4px;
                opacity: 1;
                border: 1px solid #707070;
                margin-right: 29px;
                background: url('../../assets/tuanBg.png') no-repeat;
                background-size: 100% 100%;
                font-size: 12px;
                color: #FFFFFF;
                >div{
                  display: flex;
                  justify-content: center;
                  align-items: center;
                  width: 100%;
                  height: 100%;
                  padding: 0px 10px;
                }
              }
              .card-right{
                display: flex;
                flex-direction: column;
                .card-right-class{
                  font-size: 16px;
                  margin-bottom: 13px
                }
                .card-right-class1{
                  font-size: 18px;
                  margin-bottom: 18px
                }
                .card-right-name{
                  font-size: 18px;
                  font-weight: bold;
                  margin-bottom: 18px
                }
                .card-right-teacher{
                  font-size: 14px;
                }
              }
          }
          }
        }
      }
    }
}
</style>
